@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --radial-gradient-background: transparent;
  --solid-color-background: 15, 15, 15;
  --overlay-color: 255, 255, 255;
}

@font-face {
  font-family: "Chivo";
  src: local("chivo"),
    url("../fonts/Chivo-VariableFont_wght.ttf") format("woff2");
}
@font-face {
  font-family: "Fugaz";
  src: local("fugaz"), url("../fonts/FugazOne-Regular.ttf") format("woff2");
}
@font-face {
  font-family: "Nrt";
  src: local("nrt"), url("../fonts/NRT-Reg.ttf") format("woff2");
}

body {
  background-color: #151515;
}

body.no-scroll {
  overflow: hidden;
}

.scrollbar-hide::-webkit-scrollbar {
  display: none;
}
.scrollbar-hide {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.radial-gradient {
  background: radial-gradient(
      circle at 50% 0%,
      rgba(var(--radial-gradient-background), 0.05) 0%,
      transparent 60%
    )
    rgba(var(--solid-color-background), 1);
}

.linear-mask {
  mask-image: linear-gradient(
    -75deg,
    white calc(var(--x) + 20%),
    transparent calc(var(--x) + 30%),
    white calc(var(--x) + 100%)
  );
  -webkit-mask-image: linear-gradient(
    -75deg,
    white calc(var(--x) + 20%),
    transparent calc(var(--x) + 30%),
    white calc(var(--x) + 100%)
  );
}

.linear-overlay {
  background-image: linear-gradient(
    -75deg,
    rgba(var(--overlay-color), 0.1) calc(var(--x) + 20%),
    rgba(var(--overlay-color), 0.5) calc(var(--x) + 25%),
    rgba(var(--overlay-color), 0.1) calc(var(--x) + 100%)
  );
  mask: linear-gradient(black, black) content-box, linear-gradient(black, black);
  -webkit-mask: linear-gradient(black, black) content-box,
    linear-gradient(black, black);
  mask-composite: exclude;
  -webkit-mask-composite: xor;
}
